<template>
  <div>
    <h2 v-html="$t('search_dairy_segmentation_title')"></h2>
    <!-- MBH-Ident -->
    <v-text-field
      id="search_dairy_ident"
      v-model="searchCardStore.Dairy_ident"
      :rules="[$rules.isNumericList && rules.required]"
      :label="$t('search_dairy_ident')"
      type="text"
      @keyup.enter="search"
    />
    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <!-- BLW Kreditoren-Nr. -->
      <v-text-field id="search_dairy_sapno" v-model="searchCardStore.Dairy_sapNo" :label="$t('search_dairy_sapno')" type="number" @keyup.enter="search" />
      <!-- Zulassungs-Nr -->
      <v-text-field
        id="search_dairy_permitno"
        v-model="searchCardStore.Dairy_permitNo"
        :label="$t('search_dairy_permitno')"
        type="text"
        @keyup.enter="search"
      />
      <!-- Zuständiger Mitarbeiter -->
      <v-select
        id="search_dairy_personid"
        v-if="$privileges.has({ path: '/persons', permission: 'read' })"
        v-model="searchCardStore.Dairy_responsibilityGroup"
        :label="$t('search_dairy_personid')"
        :items="responsibilityGroups"
        :item-title="this.$getLangKey()"
        item-value="name"
        clearable
        @keyup.enter="search"
      />
      <!-- Status -->
      <v-select
        id="search_dairy_status"
        v-model="searchCardStore.Dairy_status"
        :label="$t('search_dairy_status')"
        :items="search_dairy_status"
        item-title="text"
        item-value="id"
        clearable
        @keyup.enter="search"
      />
      <!-- Kanton -->
      <v-select
        id="search_dairy_cantonid"
        v-model="searchCardStore.Dairy_cantonId"
        :label="$t('search_dairy_cantonid')"
        :items="cantonsIncVirtual"
        :item-title="this.$getLangKey()"
        item-value="id"
        clearable
        @keyup.enter="search"
      />
    </div>
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { defineComponent } from 'vue'
import { useResponsibilityGroupsStore } from '@/store/enums/ResponsibilityGroupsStore'
import { useSearchDairyStore } from '@/store/SearchDairyStore'
import { useCantonsStore } from '@/store/enums/CantonsStore'

export default defineComponent({
  name: 'searchDairySegmentation',
  extends: baseSearchVue,
  data() {
    return {
      expanded: false,
      search_dairy_status: [
        { id: 1, text: this.$t('search_dairy_active') },
        { id: 0, text: this.$t('search_dairy_not_active') }
      ],
      rules: {
        required: (value) => this.validateMbhId(value) || this.$t('search_dairy_mbh_id_not_valid')
      }
    }
  },
  computed: {
    responsibilityGroups() {
      return useResponsibilityGroupsStore().responsibilityGroups
    },
    workingPlaces() {
      return useResponsibilityGroupsStore().workingPlaces
    },
    cantonsIncVirtual() {
      return useCantonsStore().CantonsIncVirtual
    }
  },
  methods: {
    validateMbhId(id) {
      if (id === null) return true
      let re = /^((\d+(,\d+)?)+)?$/
      let result = re.test(id)
      return result
    }
  },
  beforeMount() {
    if (this.$privileges.has({ path: '/persons', permission: 'read' })) {
      useReportingObligationsStore().fetchResponsibilityGroups()
    }
    useCantonsStore().fetchCantonsIncVirtual()
    this.setSearchCardStore(useSearchDairyStore(), true)
  }
})
</script>
